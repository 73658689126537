<template>
  <div class="genera-statistics">
    <Shadow @confirm="tableShow = true" ></Shadow>
    <div class="search-box">
      <span>选择时间</span>
      <div
        class="date-type"
        :class="searchFormData.dateType === item.value ? 'active' : ''"
        v-for="item in dateTypeOption"
        :key="item.value"
        @click="changeDateType(item)"
      >
        <span>{{ item.label }}</span>
      </div>
      <div style="width: 400px;margin: 0 10px;">
        <el-date-picker
          style="border-radius: 50px;"
          size="small"
          v-model="searchFormData.timeRange"
          type="daterange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
        ></el-date-picker>
      </div>
      <el-button size="small" icon="el-icon-search" type="primary" :loading="searchLoading" round @click="getData"
        >查询</el-button
      >
    </div>
    <div class="data-box">
      <div class="data-item" v-for="(item, index) in dataList" :key="index">
        <div class="data-item-title flex">
          <div class="flex">
            <el-tooltip
              v-if="item.explain"
              class="item"
              effect="dark"
              :content="item.explain"
              placement="top"
            >
              <img
                style="width: 12px;height: 12px;margin-right: 4px;"
                src="@/assets/images/icon_explain.png"
                alt
              />
            </el-tooltip>

            <span>{{ item.name }}</span>
          </div>
          <div class="flex" style="margin-left: 5px;">
            <img
              style="width: 8px;"
              :src="
                item.thanUp == '01'
                  ? upGreenImg
                  : item.thanUp == '02'
                  ? downRedImg
                  : item.thanUp == '00'
                  ? equationBlueImg
                  : ''
              "
              alt=""
            />
            <span
              v-if="searchFormData.dateType"
              :class="
                item.thanUp == '01'
                  ? 'txt-green'
                  : item.thanUp == '02'
                  ? 'txt-red'
                  : item.thanUp == '00'
                  ? 'txt-blue'
                  : ''
              "
              style="margin-left: 3px;"
              >{{
                Math.abs(item.thanData) == 0
                  ? "持平"
                  : Math.abs(item.thanData) + "%"
              }}</span
            >
          </div>
        </div>
        <div class="data-item-content flex flex-jb">
          <div class="left-num">
            <span>{{ item.chooseData }}</span>
            <span class="dan">{{ item.unit }}</span>
          </div>
          <span v-if="searchFormData.dateType">{{searchFormData.dateType=='01'?'昨日':searchFormData.dateType=='03'?'上周':searchFormData.dateType=='05'?'上月':''}}{{ item.contrastData }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { http } from "../../../../../utils/request/quickOrder";
import Shadow from "../Shadow.vue";
export default {
  components: { Shadow },
  data() {
    return {
      merchantId: '',
      tableShow: false,
      searchLoading: false,
      dateTypeOption: [
        { label: "今日", value: "01" },
        { label: "本周", value: "03" },
        { label: "本月", value: "05" },
      ],
      searchFormData: {
        dateType: "01",
        timeRange: null,
      },
      downRedImg: require("../../../../../assets/images/home/icon_xhqs_.png"),
      upGreenImg: require("../../../../../assets/images/home/icon_shqs_.png"),
      equationBlueImg: require("../../../../../assets/images/home/951@2x.png"),
      dataList: [],
    };
  },
  created() {
    this.merchantId=this.$route.query.id || ''
  },
  watch: {
    tableShow(val){
      if(val){
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      console.log(this.searchFormData.timeRange,'this.searchFormData.timeRange');
      let startTime = ""
      let endTime = ""
      if(this.searchFormData.timeRange && this.searchFormData.timeRange.length){
        startTime = this.searchFormData.timeRange[0]
        endTime = this.searchFormData.timeRange[1]
        this.searchFormData.dateType = ""
      }else if(!this.searchFormData.dateType){
        this.searchFormData.dateType = "01"
      }
      let params = {
        merchantId:this.merchantId,
        dateType: this.searchFormData.dateType,
        startTime,
        endTime
      }
      this.searchLoading = true
      http.findStatisticData(params).then(res => {
        if(res.code === 1){
          this.dataList = res.data;
        }
        this.searchLoading = false
      }).catch(()=>{
        this.searchLoading = false
      })
    },
    changeDateType(item) {
      this.searchFormData.timeRange = null
      this.searchFormData.dateType = item.value;
      this.getData()
    }
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.genera-statistics {
  position: relative;
  padding: 20px 0 0;
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .txt-green {
    color: #49a915;
  }
  .txt-red {
    color: #ff4949;
  }
  .txt-blue {
    color: #4c84ff;
  }
  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding: 0 20px;
    .date-type {
      width: 60px;
      height: 32px;
      background: #f9fbfd;
      border-radius: 16px;
      border: 1px solid #d9e6ff;
      font-weight: 400;
      font-size: 14px;
      color: #8492a6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      &.active {
        background: #0981ff;
        color: #ffffff;
      }
    }
  }
  .data-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .data-item {
      width: 199px;
      height: 84px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.16);
      border-radius: 6px;
      border: 1px solid #e9ecf7;
      padding: 14px 10px 0;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      margin: 19px;
      .data-item-content {
        margin-top: 5px;
        .left-num {
          font-weight: 500;
          font-size: 24px;
          color: #333333;
          line-height: 34px;
          .dan {
            font-weight: 500;
            font-size: 12px;
            color: #333333;
          }
        }
        .right-num {
          font-weight: 400;
          font-size: 12px;
          color: #8492a6;
          line-height: 15px;
        }
      }
    }
  }
}
</style>
