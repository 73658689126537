import request from "./request";
export const http = {
  //快捷报价订单列表
  getQuickQuoteOrderList(params) {
    return request({
      method: "POST",
      url: "/merchant/pc/quick/quote/order/getQuickQuoteOrderList",
      data: params,
    });
  },
  //导出excel
  getQuickQuoteOrderExcel(params) {
    return request({
      method: "POST",
      url: "/merchant/pc/quick/quote/order/getQuickQuoteOrderExcel",
      data: params,
      responseType: "blob",
    });
  },
  //快捷报价详情
  getExamineOrQuickInfo(params) {
    return request({
      method: "GET",
      url: "/merchant/pc/quick/quote/order/getExamineOrQuickInfo",
      params: params,
    });
  },
  //审单数据-审单统计
  findStatisticData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/findStatisticData",
      data: params,
    });
  },
  //审单数据-业绩排行
  findRankListData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/findRankListData",
      data: params,
    });
  },
  // 审单数据-业绩排行-导出
  excelRankListData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/excelRankListData",
      data: params,
      responseType: "blob",
    });
  },
  //审单数据-业绩排行-转单下沉
  findRankListTransData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/findRankListTransData",
      data: params,
    });
  },
  //审单数据-业绩排行-拒绝报价下沉
  findRankListRejectData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/findRankListRejectData",
      data: params,
    });
  },
  //导出-审单数据-业绩排行-转单下沉
  excelRankListTransData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/excelRankListTransData",
      data: params,
      responseType: "blob",
    });
  },
  //导出-审单数据-业绩排行-转单下沉
  excelRankListRejectData(params) {
    return request({
      method: "POST",
      url: "/platform/pc/examine/quote/statistic/excelRankListRejectData",
      data: params,
      responseType: "blob",
    });
  },
};
