<template>
  <div class="performance-ranking">
    <Shadow @confirm="tableShow = true"></Shadow>
    <div class="tab-box">
      <GardenSlwct
        :tab-data="tabData"
        :activeIndex="activeIndex"
        width="90px"
        border-radius="40px"
        @tabAction="tabAction"
      >
      </GardenSlwct>
    </div>
    <div class="flex flex-jb">
      <div class="search-box">
        <div class="select-box">
          <span class="select-box-lable">品牌名称</span>
          <el-select
            size="small"
            v-model="searchFormData.brandId"
            clearable
            @change="selectchangeBrand"
            filterable
            placeholder="请选择品牌"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-box">
          <span class="select-box-lable">机型名称</span>
          <el-select
            :disabled="!searchFormData.brandId"
            size="small"
            v-model="searchFormData.machineId"
            clearable
            @change="selectchangemachine"
            filterable
            :placeholder="
              searchFormData.brandId ? '请选择机型' : '请先选择品牌'
            "
          >
            <el-option
              v-for="item in phoneList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="select-box">
          <span class="select-box-lable">机型规格</span>
          <el-select
            :disabled="!searchFormData.machineId"
            size="small"
            v-model="searchFormData.machineCapacity"
            clearable
            filterable
            :placeholder="
              searchFormData.machineId ? '机型规格' : '请先选择机型'
            "
          >
            <el-option
              v-for="item in machineCapacityList"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div> -->
        <div class="select-box">
          <span class="select-box-lable">选择成色</span>
          <el-select
            size="small"
            v-model="searchFormData.healthCode"
            placeholder="请输入选择成色"
            filterable
            clearable
          >
            <el-option
              v-for="item in healthList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            ></el-option>
          </el-select>
        </div>
        <div class="flex">
          <span>选择时间</span>
          <div
            class="date-type"
            :class="searchFormData.dateType === item.value ? 'active' : ''"
            v-for="item in dateTypeOption"
            :key="item.value"
            @click="changeDateType(item)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div style="margin-left: 10px; margin-right: 10px">
          <el-date-picker
            @change="changeTimeday"
            style="border-radius: 50px"
            size="small"
            v-model="searchFormData.dateStr"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div style="margin-right: 0px">
          <el-col :span="10">
            <el-time-select
              placeholder="起始时间"
              size="small"
              clearable
              v-model="searchFormData.startInDayHHmm"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }"
            >
            </el-time-select>
          </el-col>
          <el-col :span="10">
            <el-time-select
              size="small"
              clearable
              placeholder="结束时间"
              v-model="searchFormData.endInDayHHmm"
              :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
                minTime: searchFormData.startInDayHHmm,
              }"
            >
            </el-time-select>
          </el-col>
        </div>
        <div>
          <el-button
            size="small"
            icon="el-icon-search"
            type="primary"
            round
            :loading="searchLoading"
            @click="handleCurrentChange(1)"
            >查询</el-button
          >
        </div>
      </div>
    </div>
    <div class="footbgf_action">
      <el-button
        :class="[
          'default_style_btn',
          searchFormData.queryType == '01' ? 'default_style_btn_active' : '',
        ]"
        round
        @click="buttonclick('01')"
        style="border-color: #2384fc"
      >
        成交率
      </el-button>
      <el-button
        :class="[
          'default_style_btn',
          searchFormData.queryType == '02' ? 'default_style_btn_active' : '',
        ]"
        type="primary"
        round
        @click="buttonclick('02')"
        style="border-color: #2384fc"
      >
        成交量
      </el-button>
      <el-button
        :class="[
          'default_style_btn',
          searchFormData.queryType == '03' ? 'default_style_btn_active' : '',
        ]"
        type="primary"
        round
        @click="buttonclick('03')"
        style="border-color: #2384fc"
      >
        平均报价
      </el-button>
    </div>
    <div class="bootom_tli" v-if="!isScucess">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: column;
        "
      >
        <img
          src="@/assets/images/selectModel.png"
          alt
          style="display: block; width: 100px; height: 80px"
        />
        <p style="font-weight: 400; font-size: 18px; color: #0981ff">
          请先选择完整条件查询
        </p>
      </div>
    </div>
    <div class="Polyline" id="chartLineBoxDeal"></div>

    <!-- 出货价调整记录 -->
    <el-dialog
      title="出货价调整记录"
      :visible.sync="codeRecordShow"
      :close-on-click-modal="false"
      width="800px"
      @closed="closeExport"
    >
      <div class="cance_top">
        <div>
          机型容量：{{ timeAlterprice.machineName }}
          <span> {{ timeAlterprice.machineCapacity }}</span>
        </div>
        <div>
          成色：{{
            searchFormData.healthCode == "Nice"
              ? "靓机"
              : searchFormData.healthCode == "Little_Snow"
              ? "小花"
              : searchFormData.healthCode == "Big_Snow"
              ? "大花"
              : searchFormData.healthCode == "Out_Burst"
              ? "外爆"
              : searchFormData.healthCode == "Inner_Burst"
              ? "内爆"
              : searchFormData.healthCode == "Nice_1"
              ? "小花靓"
              : searchFormData.healthCode == "Little_Snow_1"
              ? "花机"
              : searchFormData.healthCode == "Big_Snow_1"
              ? "小爆角机"
              : searchFormData.healthCode == "Big_Snow_2"
              ? "小后爆机"
              : searchFormData.healthCode == "Out_Burst_1"
              ? "前爆"
              : "无法开机"
          }}
        </div>
        <div>
          记录日期：{{
            searchFormData.dateType == "01" ? "今日" : searchFormData.dateStr
          }}
        </div>
        <div>记录时段：{{ timeDuan }}</div>
      </div>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="50px" align="center">
        </el-table-column>
        <el-table-column prop="oldPrice" align="center" label="调整前出货价">
        </el-table-column>
        <el-table-column prop="newPrice" align="center" label="调整后出货价">
        </el-table-column>
        <el-table-column prop="priceDifference" align="center" label="调整值">
        </el-table-column>
        <el-table-column prop="changeTime" align="center" label="调整时间">
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="closeExport">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import Shadow from "../Shadow.vue";
import moment from "moment";
const echarts = require("echarts");
export default {
  components: {
    GardenSlwct,
    Shadow,
  },
  data() {
    return {
      merchantId: "",
      tableData: [],
      codeRecordShow: false,
      isScucess: false,
      machineCapacityList: [],
      healthCode: "",
      tableShow: false,
      searchLoading: false,
      tabData: ["模板渠道"],
      activeIndex: 0,
      dateTypeOption: [{ label: "今日", value: "01" }],
      machineTemplateId: "",
      searchFormData: {
        brandId: "",
        healthCode: "",
        // machineCapacity: "",
        machineId: "",
        queryType: "", //排序类别【成交率：01，成交价：02，审单报价：03】
        dateType: "01",
        endInDayHHmm: "",
        startInDayHHmm: "",
        dateStr: "",
      },
      brandList: [],
      phoneList: [],
      condition: [],
      isPlaceAnCodeOrder: 0,
      valueTime: "",
      startTime: "",
      endTime: "",
      healthList: [],
      dateType: "week",
      dataValueX: [],
      dataValueY: [],
      dataValueY2: [],
      seriesArr: [],
      companyId: "",
      InquirySelectList: [],
      myEcharts: null,
      timeAlterprice: {},
      timeDuan: "",
      legendData:[]
    };
  },
  created() {
    this.merchantId=this.$route.query.id || ''
    // this.getChildHealthListTile();
    // this.selectBrandList();
    // this.getExamineMachineTemplateList();
  },
  mounted() {
    var thats = this;
    this.myEcharts = echarts.init(document.getElementById("chartLineBoxDeal"));
    this.myEcharts.on("click", function (params) {
      // 控制台打印数据的名称
      console.log(params, "点击折线图圆点数据");
      thats.timeAlterprice = params.data;
      thats.tableData = params.data.changePriceList;
      let arrTime = params.data.dataTime.split(":");
      let leftTi = "";
      if (Number(arrTime[0]) == 23) {
        leftTi = "23:59";
      } else {
        if (Number(arrTime[0]) > 8) {
          leftTi = Number(arrTime[0]) + 1 + ":00";
        } else {
          leftTi = "0" + (Number(arrTime[0]) + 1) + ":00";
        }
      }
      thats.timeDuan =  params.data.dataTime + "-" + leftTi;
      thats.$nextTick(() => {
        thats.codeRecordShow = true;
      });
      console.log(thats.timeDuan, thats.codeRecordShow);
    });
  },
  watch: {
    tableShow(val) {
      if (val) {
        this.getChildHealthListTile();
        this.selectBrandList();
        this.getExamineMachineTemplateList();
      }
    },
  },
  methods: {
    closeExport() {
      this.timeAlterprice = {};
      this.codeRecordShow = false;
    },
    selectchangeBrand(e) {
      console.log(e);
      if (e) {
        this.getMachineSelectList(e);
      } else {
        this.searchFormData.machineId = "";
        // this.searchFormData.machineCapacity = "";
      }
    },
    selectchangemachine(e) {
      console.log(e);
      if (e) {
        this.getExamineredfdMachineCapacityList(e);
      } else {
        // this.searchFormData.machineCapacity = "";
      }
    },
    // 获取成色列表
    getChildHealthListTile() {
      _api.getChildHealthListTile({merchantId:this.merchantId}).then((res) => {
        if (res.code === 1) {
          this.healthList = res.data;
        }
      });
    },
    // 获取品牌下拉列表
    selectBrandList() {
      //品牌
      _api.getExamineSelectBrandList({merchantId:this.merchantId}).then((res) => {
        if (res.code === 1) {
          this.brandList = res.data;
        }
      });
    },
    //机型
    getMachineSelectList(id) {
      _api.getMachineSelectList({ brandId: id,merchantId:this.merchantId, }).then((res) => {
        if (res.code === 1) {
          this.phoneList = res.data;
        }
      });
    },
    //规格
    getExamineredfdMachineCapacityList(id) {
      _api.getExamineredfdMachineCapacityList({ id,merchantId:this.merchantId, }).then((res) => {
        if (res.code === 1) {
          this.machineCapacityList = res.data;
        }
      });
    },
    // 获取模板名称列表
    getExamineMachineTemplateList() {
      _api.getChannelFeeTemplateSelectList({merchantId:this.merchantId}).then((res) => {
        if (res.code === 1) {
          if (res.data && res.data.length) {
            this.tabData = res.data.map((item) => {
              return {
                text: item.name,
                value: item.id,
                hideNum: true,
              };
            });
            this.machineTemplateId = res.data[0].id;
          }
        }
      });
    },
    tabAction(v) {
      this.activeIndex = v;
      this.machineTemplateId = this.tabData[v].value;
      this.handleCurrentChange();
    },
    changeTimeday(v) {
      console.log(v);
      if (v !== null) {
        this.searchFormData.dateType = "";
      }
    },
    changeDateType(item) {
      this.searchFormData.dateType = item.value;
      this.searchFormData.dateStr = "";
    },
    buttonclick(num) {
      this.searchFormData.queryType = num;
      this.handleCurrentChange();
    },
    handleCurrentChange() {
      this.dataValueY = [];
      this.dataValueX = [];
      this.seriesArr = [];
      const vo = {
        merchantId:this.merchantId,
        channelTemplateId: this.machineTemplateId,
        ...this.searchFormData,
      };
      _api
        .findQuoteAnalysisFoldLine(vo)
        .then((res) => {
          if (res.code === 1) {
            this.isScucess = true;
            for (let i = 0; i < res.data.length; i++) {
              this.legendData.push(res.data[i].machineCapacity)
              this.dataValueY.push(
                res.data[i].dataList.map((item) => {
                  return {
                    machineCapacity: res.data[i].machineCapacity,
                    machineName: res.data[i].machineName,
                    ...item,
                  };
                })
              );
            }
            console.log(this.dataValueY, "每个规格总数据");
            this.seriesArr = this.dataValueY.map((item, index) => {
              return {
                name:item[0].machineCapacity,
                type: "line",
                // smooth: true,
                data: item.map((itm) => {
                  return {
                    name: itm.machineName,
                    value: itm.orderData,
                    ...itm,
                  };
                }),
                color:
                  index == 0
                    ? "#5470c6"
                    : index == 1
                    ? "#0981ff"
                    : index == 2
                    ? "#3adeaf"
                    : index == 3
                    ? "#fe6666"
                    : index == 4
                    ? "#5beb36"
                    : "#9074ff",
              };
            });
            console.log(this.seriesArr, "折线总数据");
            this.dataValueX.push(
              ...res.data[0].dataList.map((item) => {
                return item.dataTime;
              })
            );
            console.log(this.dataValueX, "时间轴数据");
            var that = this;
            const option = {
              tooltip: {
                trigger: "item", // trigger: 'item'//值为axis显示该列下所有坐标轴对应数据，值为item时只显示该点数据
                formatter: (params) => {
                  console.log(params);
                  //trigger: 'axis'时 params 是一个数组，数组中包含每个系列的数据信息
                  //trigger: 'item'时 params 是一个对象，显示鼠标点的数据信息
                  let result = "";
                  // params.forEach(function (item) {
                  //   // item 是每一个系列的数据
                  //   const titlename = that.searchFormData.queryType;
                  //   console.log(that.searchFormData.queryType);
                  //   const marker = item.marker; // 标志图形
                  //   result += `${marker}规格: ${item.data.machineCapacity}<br/>调整前: ${item.data.oldPrice}<br/>差值: ${item.data.priceDifference}<br/>调整后: ${item.data.newPrice}<br/>`;
                  // });
                  // return result;
                  const titlename = that.searchFormData.queryType;
                  console.log(
                    that.searchFormData.queryType,
                    "排序类别【成交率：01，成交量：02，审单报价：03】"
                  );
                  const marker = params.marker; // 标志图形
                  if (titlename == "01") {
                    result = `${marker}时间点: ${params.data.dataTime}<br/>机型: ${params.data.machineName}<br/>规格: ${params.data.machineCapacity}<br/>成交率: ${params.data.orderData}%<br/>改价次数: ${params.data.changePriceList.length}<br/>`;
                  } else if (titlename == "02") {
                    result = `${marker}时间点: ${params.data.dataTime}<br/>机型: ${params.data.machineName}<br/>规格: ${params.data.machineCapacity}<br/>成交量: ${params.data.orderData}<br/>改价次数: ${params.data.changePriceList.length}<br/>`;
                  } else {
                    result = `${marker}时间点: ${params.data.dataTime}<br/>机型: ${params.data.machineName}<br/>规格: ${params.data.machineCapacity}<br/>平均报价: ${params.data.orderData}<br/>改价次数: ${params.data.changePriceList.length}<br/>`;
                  }
                  return result;
                },
              },
              xAxis: {
                type: "category",
                boundaryGap: false,
                nameLocation: "start",
                data: that.dataValueX,
              },
              legend: {
                right: "10%",
                data: that.legendData,
              },
              yAxis: {
                show: true, // 是否显示 y 轴
                position: "top", // y 轴的位置（'top'，'bottom'）
                name: "",
                type: "value",
              },
              series: that.seriesArr,
            };
            console.log(option);
            that.$nextTick(() => {
              that.myEcharts.setOption(option, true);
            });
          }
        })
        .catch((err) => {
          console.log("cuoj1");
          this.isScucess = false;
        });
    },
    seachEcharts() {
      this.dateType = "";
      if (this.valueTime !== null && this.valueTime) {
        (this.startTime = this.valueTime[0]),
          (this.endTime = this.valueTime[1]);
      } else {
        this.dateType = "week";
        this.startTime = "";
        this.endTime = "";
      }
      console.log(this.startTime);
      console.log(this.endTime);

      this.echartsData();
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.performance-ranking {
  position: relative;
  padding: 20px;

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .tab-box {
    margin-bottom: 16px;
  }

  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    flex-wrap: wrap;

    & > div {
      margin-bottom: 20px;
    }

    .date-type {
      width: 60px;
      height: 32px;
      background: #f9fbfd;
      border-radius: 16px;
      border: 1px solid #d9e6ff;
      font-weight: 400;
      font-size: 14px;
      color: #8492a6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;

      &.active {
        background: #0981ff;
        color: #ffffff;
      }
    }

    .select-box {
      margin-right: 15px;
      display: flex;
      align-items: center;

      .select-box-lable {
        flex-shrink: 0;
        margin-right: 8px;
      }

      /deep/.el-input--small .el-input__inner {
        border-radius: 50px;
      }
    }
  }

  /deep/.el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }

  /deep/.el-table--border th.is-center {
    border-bottom: 1px solid #c4d7fd !important;
  }
}
.cance_top {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footbgf_action {
  width: 100%;
  margin: 0px 0 20px 0px;
  .default_style_btn {
    margin-right: 10px;
    border-color: #2384fc;
    background: #fff;
    color: #2384fc;
    transition: all 0.3s;
  }

  .default_style_btn_active {
    color: #fff;
    background: #2384fc;
  }
}
.Polyline {
  width: 100%;
  height: 400px;
  > div {
    width: 100% !important;
  }
}
.bootom_tli {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50px;
}
</style>