<template>
  <div class="examine-statistics">
    <div class="card-box">
        <div class="card-title">
            <span>审单统计</span>
        </div>
        <div class="card-content">
            <GeneralStatistics></GeneralStatistics>
        </div>
    </div>
    <div class="card-box">
        <div class="card-title">
            <span>业绩排行</span>
        </div>
        <div class="card-content">
            <PerformanceRanking></PerformanceRanking>
        </div>
    </div>
    <!-- <div class="card-box">
        <div class="card-title">
            <span>详细数据</span>
        </div>
        <div class="card-content">
            <DetailedData></DetailedData>
        </div>
    </div> -->
    <div class="card-box1">
        <div class="card-title1">
            <el-tabs v-model="activeName"  style="width: 100%;">
                <el-tab-pane label="报价基础数据分析" name="01"></el-tab-pane>
                <el-tab-pane label="实时折线图分析" name="02"></el-tab-pane>
                <el-tab-pane label="详细数据" name="03"></el-tab-pane>
            </el-tabs>
        </div>
        <BaseData v-if="activeName=='01'"></BaseData>
        <LineData v-if="activeName=='02'"></LineData>
        <DetailedData v-if="activeName=='03'"></DetailedData>
    </div>
  </div>
</template>

<script>
import GeneralStatistics from "./components/GeneralStatistics";
import PerformanceRanking from "./components/PerformanceRanking";
import DetailedData from "./components/DetailedData";
import BaseData from "./components/BaseData"
import LineData from "./components/LineData"
export default {
    components: { GeneralStatistics, PerformanceRanking, DetailedData,BaseData,LineData },
    data() {
      return {
        activeName: '01'
      };
    },
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.examine-statistics{
    .card-box{
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 16px;
        .card-title{
            position: relative;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 28px;
            padding-left: 17px;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EFF2F7;
            &::before{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                content: " ";
                width: 4px;
                height: 16px;
                background: #0981FF;
                border-radius: 2px;
            }
        }
        .card-content{
            // padding: 0 18px;
        }
    }
    .card-box1{
        width: 100%;
        background: #FFFFFF;
        border-radius: 4px;
        margin-bottom: 16px;
        .card-title1{
            position: relative;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            line-height: 28px;
            padding-left: 17px;
            height: 50px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EFF2F7;
            &::before{
                position: absolute;
                top: 0;
                bottom: 13px;
                left: 0;
                margin: auto;
                content: " ";
                width: 4px;
                height: 16px;
                background: #0981FF;
                border-radius: 2px;
            }
        }
        .card-content{
            // padding: 0 18px;
        }
    }
}
</style>