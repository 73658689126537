<template>
  <div class="performance-ranking">
    <Shadow @confirm="tableShow = true" ></Shadow>
    <div class="flex flex-jb">
      <div class="search-box">
        <span>选择时间</span>
        <div
          class="date-type"
          :class="searchFormData.dateType === item.value ? 'active' : ''"
          v-for="item in dateTypeOption"
          :key="item.value"
          @click="changeDateType(item)"
        >
          <span>{{ item.label }}</span>
        </div>
        <div style="width: 400px;margin: 0 10px;">
          <el-date-picker
            style="border-radius: 50px;"
            size="small"
            v-model="searchFormData.timeRange"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <el-button
          size="small"
          icon="el-icon-search"
          type="primary"
          round
          :loading="searchLoading"
          @click="getData"
          >查询</el-button
        >
      </div>
      <el-button size="small" icon="el-icon-download" type="success" :loading="exportLoading" @click="exportExcel"
        >导出Excel</el-button
      >
    </div>
    <div>
      <el-table
        v-loading="searchLoading"
        :data="tableData"
        style="width: 100%"
        border
        :default-sort="{ prop: 'dealRate', order: 'descending' }"
        @sort-change="sortTableRank"
      >
        <el-table-column prop="date" label="排行" width="70" align="center">
          <template slot-scope="{ row }">
            <div class="numBG_1" v-if="row.rowNum == 1">1</div>
            <div class="numBG_2" v-else-if="row.rowNum == 2">2</div>
            <div class="numBG_3" v-else-if="row.rowNum == 3">3</div>
            <div v-else>{{ row.rowNum }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="examineQuoterName" label="审单员" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.examineQuoterName||"- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examineAvgTime"
          label="平均审单时长"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="平均审单时长=总审单时长/审单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            平均审单时长
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.examineAvgTime||"- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examineNum"
          label="审单量"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
        <template slot-scope="{ row }">
            <span>{{ row.examineNum||"- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dealNum" label="成交量" sortable="custom" :sort-orders="['descending', 'ascending']" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.dealNum||"- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="dealRate"
          label="成交率"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="成交率=成交量/审单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            成交率
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.dealRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="grossProfitRate"
          label="毛利率"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="毛利率=毛利额/成交额*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            毛利率
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.grossProfitRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="examineRate"
          label="审单占比"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="审单占比=审单量/推单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            审单占比
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.examineRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="changeNum"
          label="转单量"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
        <template slot-scope="{ row }" >
          <span style="text-decoration: underline;
    color: #0981ff;
    cursor: pointer" @click="clickunquote(row)">{{ row.changeNum}}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="changeRate"
          label="转单占比"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="转单占比=转单量/（转单量+审单量)"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            转单占比
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.changeRate }}%</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="notExamineNum"
          label="未审量"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="未审量=推单量-审单量"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            未审量
          </template>
        </el-table-column> -->
        <el-table-column
          prop="rejectQuoteNum"
          label="拒绝报价量"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="审单员审单过程中点击：“无法报价”按钮提交后的量在此展示"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            拒绝报价量
          </template>
          <template slot-scope="{ row }">
            <span style="text-decoration: underline;
    color: #0981ff;
    cursor: pointer" @click="clickreject(row)">{{ row.rejectQuoteNum }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="notExamineRate"
          label="未审占比"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="未审占比=未审量/推单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            未审占比
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.notExamineRate }}%</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="maxRate"
          label="最高报价率"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="最高报价率=最高报价量/总审单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            最高报价率
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.maxRate }}%</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
        <!--未报价量弹框-->
        <el-dialog :title="unqotetitle" :visible.sync="unquoteVisible" :close-on-click-modal="false" width="800px"
      @closed="closeunquote">
      <div style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 20px !important">
        <div style="display: flex;align-items: center;" class="mybox">
          <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="订单编号" style="margin-bottom: 0 !important;">
              <el-input v-model="form.unquoteOrderNo"></el-input>
            </el-form-item>
          </el-form>
          <el-button type="primary" size="medium" style="margin-left: 10px;" @click="searchUnquote">查询</el-button>
        </div>
        <div>
          <el-button size="medium" icon="el-icon-download" type="success" :loading="exportLoading"
            @click="exportExcelsUnquote">导出Excel</el-button>
        </div>
      </div>
      <el-table :data="unquotetableData" border style="width: 100%">
        <el-table-column type="index" label="序号" width="50px" align="center">
        </el-table-column>
        <el-table-column prop="oldPrice" align="center" label="订单号">
          <template slot-scope="{ row }">
            <div @click="handleClick(row)" style="color: #0981FF;
    text-decoration: underline;
    cursor: pointer;">
              {{ row.orderNo || "--" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phoneName" align="center" label="机型">
          <template slot-scope="{row}">
            {{ row.phoneName||"- -" }} 
          </template>
        </el-table-column>
        <el-table-column prop="capacity" align="center" label="规格">
          <template slot-scope="{row}">
            {{ row.capacity||"- -" }}
          </template>
        </el-table-column>
        <el-table-column prop="changeTime" align="center" label="渠道商家">
          <template slot-scope="{row}">
            {{ row.companyName||"- -" }}
          </template>
        </el-table-column>
        <el-table-column prop="changeTime" align="center" label="下单时间">
          <template slot-scope="{row}">
            {{ row.orderTime||"- -" }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-pagination
          background
          :current-page="pages.currentPage"
          :page-size="10"
          layout="total, prev, pager, next,jumper"
          :total="this.pages.total"
          class="el-pagination-c"
          @current-change="handleCurrentChange"
        />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { http } from "@/utils/request/quickOrder";
import { MessageBox } from "element-ui";
import Shadow from "../Shadow.vue";

export default {
  components: { Shadow },
  data() {
    return {
      tableShow: false,
      searchLoading: false,
      dateTypeOption: [
        { label: "今日", value: "01" },
        { label: "本周", value: "03" },
        { label: "本月", value: "05" },
      ],
      searchFormData: {
        dateType: "01",
        timeRange: [],
        orderType: "04",
        ascOrDesc: "01",
      },
      tableData: [],
      exportLoading: false,
      searchParams: {},
      unqotetitle: "",
      unquoteVisible:false,
      form: {
        unquoteOrderNo: "",//未报价订单号
      },
      unquotetableData:[],
      pages: {
        total: 0,
        pageSize: 10,
        currentPage: 1,
      },
      examineQuoterId:""
    };
  },
  created() {
     this.merchantId=this.$route.query.id || ''
  },
  watch: {
    tableShow(val){
      if(val){
        this.getData();
      }
    }
  },
  methods: {
    getData() {
      console.log(
        this.searchFormData.timeRange,
        "this.searchFormData.timeRange"
      );
      let startTime = "";
      let endTime = "";
      if (
        this.searchFormData.timeRange &&
        this.searchFormData.timeRange.length
      ) {
        startTime = this.searchFormData.timeRange[0];
        endTime = this.searchFormData.timeRange[1];
        this.searchFormData.dateType = "";
      } else if (!this.searchFormData.dateType) {
        this.searchFormData.dateType = "01";
      }
      let params = {
        dateType: this.searchFormData.dateType,
        orderType: this.searchFormData.orderType,
        ascOrDesc: this.searchFormData.ascOrDesc,
        startTime,
        endTime,
        merchantId:this.merchantId
      };
      this.searchParams = params
      this.searchLoading = true;
      http.findRankListData(params).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data;
        }
        this.searchLoading = false;
      }).catch(()=>{
        this.searchLoading = false
      })
    },
    changeDateType(item) {
      this.searchFormData.timeRange = null;
      this.searchFormData.dateType = item.value;
      this.getData();
    },
    sortTableRank(column) {
      console.log(column.prop, "proppropproppropprop");

      if (column.prop) {
        // 排序方式
        if (column.order == "ascending") {
          this.searchFormData.ascOrDesc = "02";
        } else if (column.order == "descending") {
          this.searchFormData.ascOrDesc = "01";
        }
        // 排序字段
        switch (column.prop) {
          case "examineAvgTime":
            this.searchFormData.orderType = "01";
            break;
          case "examineNum":
            this.searchFormData.orderType = "02";
            break;
          case "dealNum":
            this.searchFormData.orderType = "03";
            break;
          case "dealRate":
            this.searchFormData.orderType = "04";
            break;
          case "grossProfitRate":
            this.searchFormData.orderType = "05";
            break;
          case "examineRate":
            this.searchFormData.orderType = "06";
            break;
          case "changeNum":
            this.searchFormData.orderType = "07";
            break;
          case "changeRate":
            this.searchFormData.orderType = "08";
            break;
          // case "notExamineNum":
          //   this.searchFormData.orderType = "09";
          //   break;
          case "notExamineRate":
            this.searchFormData.orderType = "10";
            break;
          case "maxRate":
            this.searchFormData.orderType = "11";
            break;
          default:
            this.searchFormData.orderType = "04";
        }
      }
      this.getData();
    },
    exportExcel() {
      this.exportLoading = true;
      _api
        .excelRankListData(this.searchParams)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "业绩排行.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    },
        //未报价弹框显示
    clickunquote(row) {
      console.log(row,9999,"row")
      this.examineQuoterId=row.examineQuoterId
      console.log(this.examineQuoterId,"this.examineQuoterId")
      this.unquoteVisible = true
      this.unqotetitle = "采集订单明细-转单订单"
      let params=this.returnParms()
      http.findRankListTransData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
    },
    //拒绝报价量弹框显示
    clickreject(row) {
      this.examineQuoterId=row.examineQuoterId
      this.unquoteVisible = true
      this.unqotetitle = "采集订单明细-拒绝报价订单"
      let params=this.returnParms()
      http.findRankListRejectData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
    },
    //关闭未报价弹框
    closeunquote() {
      this.unquoteVisible = false
      this.form.unquoteOrderNo = ""
      this.examineQuoterId=""
    },
    //查询未报价或拒绝报价量
    searchUnquote() {
      if (this.unqotetitle == "采集订单明细-转单订单") {
        let params=this.returnParms()
      http.findRankListTransData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
      }
      else {
        let params=this.returnParms()
      http.findRankListRejectData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
      }
      //this.form.unquoteOrderNo
    },
    //导出未报价或拒绝报价量
    exportExcelsUnquote() {
      if (this.unqotetitle == "采集订单明细-转单订单") {

      this.exportLoading = true;
      let params=this.returnParms()
        http
          .excelRankListTransData(params)
          .then((res) => {
            console.log(res);
            const content = res;
            const blob = new Blob([content]);
            let reader = new FileReader(); // 创建读取文件对象
            reader.addEventListener("loadend", () => {
              //
              try {
                let data = JSON.parse(reader.result);
                if (data.code === 0) {
                  MessageBox.alert(data.msg, "错误提示", {
                    type: "error",
                  });
                }
              } catch (err) {
                const fileName = "转单订单.xlsx";
                if ("download" in document.createElement("a")) {
                  // 非IE下载
                  const elink = document.createElement("a");
                  elink.download = fileName;
                  elink.style.display = "none";
                  elink.href = URL.createObjectURL(blob);
                  document.body.appendChild(elink);
                  elink.click();
                  URL.revokeObjectURL(elink.href); // 释放URL 对象
                  document.body.removeChild(elink);
                } else {
                  // IE10+下载
                  navigator.msSaveBlob(blob, fileName);
                }
              }
            });
            reader.readAsText(blob, "utf-8");
          }).finally(() => {
            this.exportLoading = false;
          });
      }
      else{
        let params=this.returnParms()
      this.exportLoading = true;
        http
          .excelRankListRejectData(params)
          .then((res) => {
            console.log(res);
            const content = res;
            const blob = new Blob([content]);
            let reader = new FileReader(); // 创建读取文件对象
            reader.addEventListener("loadend", () => {
              //
              try {
                let data = JSON.parse(reader.result);
                if (data.code === 0) {
                  MessageBox.alert(data.msg, "错误提示", {
                    type: "error",
                  });
                }
              } catch (err) {
                const fileName = "拒绝报价订单.xlsx";
                if ("download" in document.createElement("a")) {
                  // 非IE下载
                  const elink = document.createElement("a");
                  elink.download = fileName;
                  elink.style.display = "none";
                  elink.href = URL.createObjectURL(blob);
                  document.body.appendChild(elink);
                  elink.click();
                  URL.revokeObjectURL(elink.href); // 释放URL 对象
                  document.body.removeChild(elink);
                } else {
                  // IE10+下载
                  navigator.msSaveBlob(blob, fileName);
                }
              }
            });
            reader.readAsText(blob, "utf-8");
          }).finally(() => {
            this.exportLoading = false;
          });
      }

    },
    //跳转订单详情
    handleClick(row) {
      let routeData = this.$router.resolve({
        path: "/RecallOrder/Details",
        query: { type: "edit", id: row.orderNo,merchantId: this.merchantId, },
      });
      window.open(routeData.href, "_blank");
    },
    handleCurrentChange(val) {
      //  暂时
      this.loading = true;
      if (val) {
        this.pages.currentPage = val;
      } else {
        this.pages.currentPage = 1;
      }
      if (this.unqotetitle == "采集订单明细-转单订单") {
        let params=this.returnParms()
      http.findRankListTransData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
      }
      else{
        let params=this.returnParms()
      http.findRankListRejectData(params).then(res=>{
        this.unquotetableData=res.data.records 
        this.pages.currentPage=res.data.current
        this.pages.total=res.data.total
      })
      }
    },
    returnParms(){
      let startTime = "";
      let endTime = "";
      if (
        this.searchFormData.timeRange &&
        this.searchFormData.timeRange.length
      ) {
        startTime = this.searchFormData.timeRange[0];
        endTime = this.searchFormData.timeRange[1];
        this.searchFormData.dateType = "";
      } else if (!this.searchFormData.dateType) {
        this.searchFormData.dateType = "01";
      }

      let params = {
        dateType: this.searchFormData.dateType,
        startTime,
        endTime,
        pageNum:this.pages.currentPage,
        pageSize:10,
        examineId:this.examineQuoterId,
        merchantId:this.merchantId
      };
      if(this.form.unquoteOrderNo){
        params.orderNo=this.form.unquoteOrderNo
      }
      return params
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.performance-ranking {
  position: relative;
  padding: 20px;
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .numBG_1 {
    background-image: url("../../../../../assets/images/imgAll/icon_01.png");
    background-size: 100%;
    color: white;
  }
  .numBG_2 {
    background-image: url("../../../../../assets/images/imgAll/icon_02.png");
    background-size: 100%;
    color: white;
  }
  .numBG_3 {
    background-image: url("../../../../../assets/images/imgAll/icon_03.png");
    background-size: 100%;
    color: white;
  }
  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    .date-type {
      width: 60px;
      height: 32px;
      background: #f9fbfd;
      border-radius: 16px;
      border: 1px solid #d9e6ff;
      font-weight: 400;
      font-size: 14px;
      color: #8492a6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      &.active {
        background: #0981ff;
        color: #ffffff;
      }
    }
  }
  /deep/.el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }
}
/deep/ .mybox .el-form-item__label {
  text-align: left !important;
}
</style>
