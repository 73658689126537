//预留差异列表,预留差异导出
import request from './request'
export const http = {
    //报价分析-基础数据
    findQuoteAnalysisBasicData(params) {
        return request({
            method: 'POST',
            url: '/platform/pc/examine/quote/statistic/findQuoteAnalysisBasicData',
            data: params
        })
    },
    //报价分析-基础数据-导出
    excelQuoteAnalysisBasicData(params={}){
        return request({
            method: 'POST',
            url: '/platform/pc/examine/quote/statistic/excelQuoteAnalysisBasicData',
            data: params,
            responseType: 'blob'
        })
    },
    //Q-获取下级机况列表（平铺）
    getChildHealthListTile(){
        return request({
            method: 'GET',
            url: '/platform/pc/examine/quote/statistic/getChildHealthListTile',
        })
    },
    //报价分析-基础数据-规格下沉
    getFindQuoteAnalysisBasicDataSkuDown(params){
        return request({
            method: 'POST',
            url: '/platform/pc/examine/quote/statistic/findQuoteAnalysisBasicDataSkuDown',
            data: params
        })
    },
}