<template>
  <div class="performance-ranking">
    <Shadow @confirm="tableShow = true"></Shadow>
    <div class="tab-box">
      <GardenSlwct :tab-data="tabData" :activeIndex="activeIndex" width="150px" border-radius="40px"
        @tabAction="tabAction">
      </GardenSlwct>
    </div>
    <!--主搜索框-->
    <div class="flex flex-jb">
      <div class="search-box">
        <div class="select-box">
          <span class="select-box-lable">品牌名称</span>
          <el-select size="small" v-model="searchFormData.brandId" clearable filterable placeholder="请选择品牌">
            <el-option v-for="item in brandList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="select-box">
          <span class="select-box-lable" @click="openSkuNum()">机型名称</span>
          <el-input placeholder="请输入机型名称" size="small" v-model="searchFormData.machineName" clearable>
          </el-input>
        </div>
        <div class="flex">
          <span>选择时间</span>
          <div class="date-type" :class="searchFormData.dateType === item.value ? 'active' : ''"
            v-for="item in dateTypeOption" :key="item.value" @click="changeDateType(item)">
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div style="width: 400px;margin-left: 10px;margin-right: 10px;">
          <el-date-picker style="border-radius: 50px;" size="small" v-model="searchFormData.timeRange" type="daterange"
            value-format="timestamp" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </div>
        <div>
          <el-button size="small" icon="el-icon-search" type="primary" round :loading="searchLoading"
            @click="handleCurrentChange(1)">查询</el-button>
        </div>
      </div>
      <el-button style="margin-bottom: 20px;" size="small" icon="el-icon-download" type="success"
        :loading="exportLoading" @click="exportExcel">导出Excel</el-button>
    </div>
    <!--主表-->
    <div>
      <el-table v-loading="searchLoading" :data="tableData" style="width: 100%" border
        :default-sort="{ prop: 'dealNum', order: 'descending' }" @sort-change="sortTableRank">
        <el-table-column type="index" align="center" label="序号" width="50" :index="(index) => {
          return (
            index +
            1 +
            (searchFormData.pageNum - 1) * searchFormData.pageSize
          );
        }
          ">
        </el-table-column>
        <el-table-column prop="brandName" label="品牌" align="center">
          <template slot-scope="{row}">
            {{ row.brandName ? row.brandName : "- -" }}
          </template>
        </el-table-column>
        <el-table-column prop="machineName" label="机型名称" align="center">
          <template slot-scope="{row}">
            {{ row.machineName ? row.machineName : "- -" }}
          </template>
        </el-table-column>
        <el-table-column prop="examineMachineSkuNum" label="规格容量" align="center">
          <template slot="header">
            规格容量
          </template>
          <template slot-scope="{ row }">
            <span style="color: #0981ff;cursor: pointer;text-decoration: underline;"
              @click="openSkuNum(row.machineName, row.machineId)">{{ row.examineMachineSkuNum ? row.examineMachineSkuNum
              : "- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dealNum" label="成交量" sortable="custom" :sort-orders="['descending', 'ascending']"
          align="center">
          <template slot="header">
            成交量
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.dealNum ? row.dealNum : "- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="dealRate" label="成交率" sortable="custom" :sort-orders="['descending', 'ascending']"
          align="center">
          <template slot="header">
            成交率
          </template>
          <template slot-scope="{ row }">
            <span v-if="row.dealRate">{{ row.dealRate}}%</span>
            <span v-else>- -</span>
          </template>
        </el-table-column>
        <el-table-column prop="dealPrice" label="累计成交额" sortable="custom" :sort-orders="['descending', 'ascending']"
          align="center">
          <template slot="header">
            累计成交额
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.dealPrice ? row.dealPrice : "- -" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="avgDealPrice" label="平均成交价" sortable="custom" :sort-orders="['descending', 'ascending']"
          align="center">
          <template slot="header">
            平均成交价
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.avgDealPrice ? row.avgDealPrice : "- -" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            prop="maxQuoteRate"
            label="推单量(单)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                推单量(单)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column> -->
        <el-table-column prop="examineNum" label="审单报价量" sortable="custom" :sort-orders="['descending', 'ascending']"
          align="center">
          <template slot="header">
            审单报价量
          </template>
          <template slot-scope="{ row }">
            <span>{{ row.examineNum ? row.examineNum : "- -" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
            prop="maxQuoteRate"
            label="审单报价率(%)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                审单报价率(%)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column> -->
        <!-- <el-table-column
            prop="maxQuoteRate"
            label="拒绝报价量(单)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                拒绝报价量(单)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column> -->
        <!-- <el-table-column
            prop="maxQuoteRate"
            label="平均报价时效(s)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                平均报价时效(s)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column> -->
          <el-table-column
            prop="maxQuoteRate"
            label="最高报价率(%)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                最高报价率(%)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
        <el-pagination @current-change="handleCurrentChange" :current-page="searchFormData.pageNum"
          :page-size="searchFormData.pageSize" layout="total, prev, pager, next, jumper" :total="searchFormData.total">
        </el-pagination>
      </div>
    </div>
    <!-- 规格容量弹框 -->
    <el-dialog :title="normstitle" :visible.sync="normsShow" :close-on-click-modal="false" width="1000px">
      <div class="tab-box">
        <!-- <GardenSlwct
          :tab-data="tabNormsData"
          :activeIndex="activeNormsIndex"
          width="90px"
          border-radius="40px"
          @tabAction="tabNormsAction"
        >
        </GardenSlwct> -->
        <el-radio-group v-model="radio1" @input="tabchange">
          <el-radio-button :label="index" v-for="(item, index) in tabNormsData" :key="index">{{ item.value
            }}({{ headViewsObj[item.key] || 0 }})</el-radio-button>
        </el-radio-group>
        <el-table :data="tableNormsData" style="width: 100%;margin: 20px auto 0;" border
          :default-sort="{ prop: 'dealNum', order: 'descending' }" @sort-change="sortTableNormsRank"
          v-loading="tableLoading">
          <el-table-column type="index" align="center" label="序号" width="50">
          </el-table-column>
          <el-table-column prop="machineCapacity" label="机型容量" align="center">
            <template slot="header">
              机型容量
            </template>
            <template slot-scope="{ row }">
              {{ row.machineName }} {{ row.machineCapacity }}
            </template>
          </el-table-column>
          <el-table-column prop="dealNum" label="成交量" sortable="custom" :sort-orders="['descending', 'ascending']"
            align="center">
            <template slot="header">
              成交量
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.dealNum }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dealRate" label="成交率(%)" sortable="custom" :sort-orders="['descending', 'ascending']"
            align="center">
            <template slot="header">
              成交率
            </template>
            <template slot-scope="{ row }">
              <span v-if="row.dealRate">{{ row.dealRate }}%</span>
              <span v-else>- -</span>
            </template>
          </el-table-column>
          <el-table-column prop="dealPrice" label="累计成交额" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              累计成交额
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.dealPrice}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="avgDealPrice" label="平均成交价" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              平均成交价
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.avgDealPrice }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="maxQuoteRate" label="推单量(单)" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              推单量(单)
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.maxQuoteRate }}%</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="examineNum" label="审单报价量" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              审单报价量
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.examineNum }}</span>
            </template>
          </el-table-column>

          <!-- <el-table-column prop="maxQuoteRate" label="审单报价率(%)" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              审单报价率(%)
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.maxQuoteRate }}%</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column prop="maxQuoteRate" label="拒绝报价量(单)" sortable="custom"
            :sort-orders="['descending', 'ascending']" align="center">
            <template slot="header">
              拒绝报价量(单)
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.maxQuoteRate }}%</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            prop="maxQuoteRate"
            label="平均报价时效(s)"
            sortable="custom"
            :sort-orders="['descending', 'ascending']"
            align="center"
          >
            <template slot="header">
                平均报价时效(s)
            </template>
            <template slot-scope="{ row }">
                <span>{{ row.maxQuoteRate }}%</span>
              </template>
          </el-table-column> -->
        </el-table>
        <!-- <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
          <el-pagination @current-change="handleCurrentChange1" :current-page="searchFormData1.pageNum"
            :page-size="searchFormData1.pageSize" layout="total, prev, pager, next, jumper"
            :total="searchFormData1.total">
          </el-pagination>
        </div> -->
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import Shadow from "../Shadow.vue";
import { http } from "@/utils/request/reserve"

export default {
  components: {
    GardenSlwct, Shadow
  },
  data() {
    return {
      merchantId: '',
      tableShow: false,
      searchLoading: false,
      tabData: ["模板渠道"],
      activeIndex: 0,
      activeNormsIndex: 0,//规格渠道对话框内部tabindex
      tabNormsData: [],//规格渠道对话框内部tab
      dateTypeOption: [
        { label: "今日", value: "01" },
        { label: "本周", value: "03" },
        { label: "本月", value: "05" },
      ],
      machineTemplateId: "",
      machineTemplateName:"",//模板名称
      searchFormData: {
        brandId: "",
        machineName: "",
        dateType: "01",
        timeRange: [],
        ascOrDesc: "01",
        orderType: "01",
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      searchFormData1: {
        brandId: "",
        machineName: "",
        dateType: "01",
        timeRange: [],
        ascOrDesc: "01",
        orderType: "01",
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: [],
      tableNormsData: [],
      modelListData: [],
      brandList: [],
      exportLoading: false,
      searchParams: {},
      normstitle: "华为",//规格容量标题,
      normsShow: false,//规格容量弹窗是否显示
      radio1: 0,//列表平铺,
      healthCode: "",//机况编码
      tableLoading: false,
      machineId: null,//机型id
      headViewsObj:{},
    };
  },
  created() {
    this.merchantId=this.$route.query.id || ''
    // this.selectBrandList()
    // this.getExamineMachineTemplateList()
  },
  watch: {
    tableShow(val) {
      if (val) {
        this.selectBrandList()
        this.getExamineMachineTemplateList()
      }
    }
  },
  methods: {
    // 获取品牌下拉列表
    selectBrandList() {
      //品牌
      _api.getExamineSelectBrandList({merchantId:this.merchantId}).then((res) => {
        if (res.code === 1) {
          this.brandList = res.data
        }
      });
    },
    // 获取模板名称列表
    getExamineMachineTemplateList() {
      _api.getChannelFeeTemplateSelectList({merchantId:this.merchantId}).then(res => {
        if (res.code === 1) {
          if (res.data && res.data.length) {
            this.tabData = res.data.map(item => {
              return {
                text: item.name,
                value: item.id,
                hideNum: true,
              }
            })

            this.machineTemplateId = res.data[0].id
            this.machineTemplateName=res.data[0].name
            this.handleCurrentChange()
          }


        }
      })
    },
    //主表格查询
    handleCurrentChange(pageNum) {
      this.searchLoading = true
      if (pageNum) {
        this.searchFormData.pageNum = pageNum
      }
      let startTime = ""
      let endTime = ""
      if (this.searchFormData.timeRange && this.searchFormData.timeRange.length) {
        startTime = this.searchFormData.timeRange[0]
        endTime = this.searchFormData.timeRange[1]
        this.searchFormData.dateType = ""
      } else if (!this.searchFormData.dateType) {
        this.searchFormData.dateType = "01"
      }
      let params = {
        merchantId:this.merchantId,
        channelTemplateId: this.machineTemplateId,
        dateType: this.searchFormData.dateType,
        machineName: this.searchFormData.machineName,
        brandId: this.searchFormData.brandId,
        orderType: this.searchFormData.orderType,
        ascOrDesc: this.searchFormData.ascOrDesc,
        startTime,
        endTime,
        pageNum: this.searchFormData.pageNum,
        pageSize: this.searchFormData.pageSize
      }
      this.searchParams = params
      http.findQuoteAnalysisBasicData(params).then(res => {
        console.log(987, res)
        if (res.code === 1) {
          this.tableData = res.data.records;
          this.searchFormData.pageNum = res.data.current
          this.searchFormData.total = res.data.total
        }
      }).catch(() => {
      }).finally(() => {
        this.searchLoading = false
      })
    },
    //弹框表格数据
    handleCurrentChange1(pageNum) {
      this.tableLoading = true
      if (pageNum) {
        this.searchFormData1.pageNum = pageNum
      }
      let startTime = ""
      let endTime = ""
      if (this.searchFormData.timeRange && this.searchFormData.timeRange.length) {
        startTime = this.searchFormData.timeRange[0]
        endTime = this.searchFormData.timeRange[1]
        this.searchFormData.dateType = ""
      } else if (!this.searchFormData.dateType) {
        this.searchFormData.dateType = "01"
      }
      let params = {
        merchantId:this.merchantId,
        channelTemplateId: this.machineTemplateId,
        dateType: this.searchFormData.dateType,
        machineName: this.searchFormData.machineName,
        brandId: this.searchFormData.brandId,
        orderType: this.searchFormData1.orderType,
        ascOrDesc: this.searchFormData1.ascOrDesc,
        startTime,
        endTime,
        pageNum: this.searchFormData1.pageNum,
        pageSize: this.searchFormData1.pageSize,
        machineId: this.machineId,
        healthCode: this.healthCode
      }
      console.log(params, 123)
      http.getFindQuoteAnalysisBasicDataSkuDown(params).then(res => {
        console.log(res, 123)
        if (res.code === 1) {
          this.tableNormsData = res.data.dataViews
          const result = res.data.headViews.reduce((acc, item) => {
              acc[item.status] = item.num;
              return acc;
          }, {});
          this.headViewsObj = result
        }
      }).catch(() => {
      }).finally(() => {
        this.tableLoading = false
      })
    },
    //主表格模板点击
    tabAction(v) {
      this.activeIndex = v;
      this.machineTemplateId =this.tabData[v].value;
      this.machineTemplateName=this.tabData[v].text
      this.handleCurrentChange(1)
    },
    //今天一周等切换
    changeDateType(item) {
      this.searchFormData.timeRange = null
      this.searchFormData.dateType = item.value;
      this.handleCurrentChange(1)
    },
    //主表格排序
    sortTableRank(column) {
      if (column.order == "ascending") {
        this.searchFormData.ascOrDesc = "02";
      } else if (column.order == "descending") {
        this.searchFormData.ascOrDesc = "01";
      }
      switch (column.prop) {
        case "dealNum":
          this.searchFormData.orderType = "01";
          break;
        case "dealRate":
          this.searchFormData.orderType = "02";
          break;
        case "dealPrice":
          this.searchFormData.orderType = "03";
          break;
        case "avgDealPrice":
          this.searchFormData.orderType = "04";
          break;
        case "examineNum":
          this.searchFormData.orderType = "05";
          break;
          case "maxQuoteRate":
          this.searchFormData.orderType = "06";
          break;
        default:
          this.searchFormData.orderType = "01";
      }
      // this.searchFormData.orderType = "01";
      this.handleCurrentChange(1)
    },
    //弹框表格排序
    sortTableNormsRank(column) {
      if (column.order == "ascending") {
        this.searchFormData1.ascOrDesc = "02";
      } else if (column.order == "descending") {
        this.searchFormData1.ascOrDesc = "01";
      }
      console.log(column,"123")
      switch (column.prop) {
        case "dealNum":
          this.searchFormData1.orderType = "01";
          break;
        case "dealRate":
          this.searchFormData1.orderType = "02";
          break;
        case "dealPrice":
          this.searchFormData1.orderType = "03";
          break;
        case "avgDealPrice":
          this.searchFormData1.orderType = "04";
          break;
        case "examineNum":
          this.searchFormData1.orderType = "05";
          break;
        default:
          this.searchFormData1.orderType = "01";
      }
      this.handleCurrentChange1(1)
    },
    //导出
    exportExcel() {
      this.exportLoading = true;
      let startTime = ""
      let endTime = ""
      if (this.searchFormData.timeRange && this.searchFormData.timeRange.length) {
        startTime = this.searchFormData.timeRange[0]
        endTime = this.searchFormData.timeRange[1]
        this.searchFormData.dateType = ""
      } else if (!this.searchFormData.dateType) {
        this.searchFormData.dateType = "01"
      }
      let params = {
        merchantId:this.merchantId,
        channelTemplateId: this.machineTemplateId,
        dateType: this.searchFormData.dateType,
        machineName: this.searchFormData.machineName,
        brandId: this.searchFormData.brandId,
        orderType: this.searchFormData.orderType,
        ascOrDesc: this.searchFormData.ascOrDesc,
        startTime,
        endTime,
        pageNum: this.searchFormData.pageNum,
        pageSize: this.searchFormData.pageSize
      }
      http
        .excelQuoteAnalysisBasicData(params)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "报价基础数据分析导出.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    },
    //打开规格容量弹框
    openSkuNum(title, id) {
      let title1 = title ? title : "测试"
      let machineTemplateName=this.machineTemplateName
      this.normsShow = true
      this.normstitle = `报价分析-${machineTemplateName}-${title1}`
      this.machineId = id
      this.getChildHealthListTile()
    },
    //获取平铺列表
    getChildHealthListTile() {
      http.getChildHealthListTile({merchantId:this.merchantId}).then(res => {
        console.log(res, "789")
        if (res.code === 1) {
          console.log(res, "789")
          if (res.data && res.data.length) {
            console.log(res, 987)
            this.tabNormsData = res.data
            this.healthCode = res.data[0].key
            this.searchFormData1.pageNum = 1
            this.handleCurrentChange1(1)
          }
        }
      })
    },
    tabchange(e) {
      this.healthCode = this.tabNormsData[e].key
      this.searchFormData1.pageNum = 1
      this.handleCurrentChange1(1)
    }

  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.performance-ranking {
  position: relative;
  padding: 20px;

  .flex {
    display: flex;
    align-items: center;
  }

  .flex-jb {
    display: flex;
    justify-content: space-between;
  }

  .tab-box {
    margin-bottom: 16px;
  }

  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    flex-wrap: wrap;

    &>div {
      margin-bottom: 20px;
    }

    .date-type {
      width: 60px;
      height: 32px;
      background: #f9fbfd;
      border-radius: 16px;
      border: 1px solid #d9e6ff;
      font-weight: 400;
      font-size: 14px;
      color: #8492a6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;

      &.active {
        background: #0981ff;
        color: #ffffff;
      }
    }

    .select-box {
      margin-right: 15px;
      display: flex;
      align-items: center;

      .select-box-lable {
        flex-shrink: 0;
        margin-right: 8px;
      }

      /deep/.el-input--small .el-input__inner {
        border-radius: 50px;
      }
    }
  }

  /deep/.el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }

  /deep/.el-table--border th.is-center {
    border-bottom: 1px solid #c4d7fd !important;
  }
}
</style>