<template>
  <div class="performance-ranking">
    <Shadow @confirm="tableShow = true" ></Shadow>
    <div class="tab-box">
      <GardenSlwct
        :tab-data="tabData"
        :activeIndex="activeIndex"
        width="90px"
        border-radius="40px"
        @tabAction="tabAction"
      >
      </GardenSlwct>
    </div>
    <div class="flex flex-jb">
      <div class="search-box">
        <div class="select-box">
          <span class="select-box-lable">品牌名称</span>
          <el-select
            size="small"
            v-model="searchFormData.brandId"
            clearable
            filterable
            placeholder="请选择品牌"
          >
            <el-option
              v-for="item in brandList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select-box">
          <span class="select-box-lable">机型名称</span>
          <el-input
            placeholder="请输入机型名称"
            size="small"
            v-model="searchFormData.machineName"
            clearable>
          </el-input>
        </div>
        <div class="flex">
          <span>选择时间</span>
          <div
            class="date-type"
            :class="searchFormData.dateType === item.value ? 'active' : ''"
            v-for="item in dateTypeOption"
            :key="item.value"
            @click="changeDateType(item)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div style="width: 400px;margin-left: 10px;margin-right: 10px;">
          <el-date-picker
            style="border-radius: 50px;"
            size="small"
            v-model="searchFormData.timeRange"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <div>
          <el-button size="small" icon="el-icon-search" type="primary" round :loading="searchLoading" @click="handleCurrentChange(1)"
            >查询</el-button
          >
        </div>
      </div>
      <el-button
        style="margin-bottom: 20px;"
        size="small"
        icon="el-icon-download"
        type="success"
        :loading="exportLoading"
        @click="exportExcel"
        >导出Excel</el-button
      >
    </div>
    <div>
      <el-table :key="dataTable" v-loading="searchLoading" :data="tableData" style="width: 100%" border :default-sort="{ prop: 'dealRate', order: 'descending' }" @sort-change="sortTableRank">
        <el-table-column type="index" align="center" label="序号" width="50">
        </el-table-column>
        <el-table-column prop="brandName" label="品牌" align="center">
        </el-table-column>
        <el-table-column prop="machineName" label="旧机机型、容量" align="center">
          <template slot-scope="{row}">
            {{ row.machineName }} {{ row.machineCapacity }}
          </template>
        </el-table-column>
        <el-table-column
          prop="dealRate"
          label="总成交率(%)"
          sortable="custom"
          :sort-orders="['descending', 'ascending']"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="总成交率=总成交量/总审单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
           总成交率
          </template>
          <template slot-scope="{ row }">
              <span v-if="row.dealRate">{{ row.dealRate }}%</span>
              <span v-else> - - </span>
            </template>
        </el-table-column>
        <el-table-column :label="item.value" align="center" v-for="(item,index) in headerList" :key="item.key">
          <el-table-column
          prop="maxQuoteRate"
          label="最高报价率(%)"
          align="center"
        >
          <template slot="header">
            <el-tooltip
              class="item"
              effect="dark"
              content="最高报价率=最高报价量/总审单量*100%"
              placement="top"
            >
              <i class="el-icon-warning-outline" />
            </el-tooltip>
            最高报价率
          </template>
          <template slot-scope="{ row }">
            <span v-if="row.dataList[index].maxQuoteRate">{{ row.dataList[index].maxQuoteRate }}%</span>
            <span v-else>- -</span>
            </template>
        </el-table-column>
          <el-table-column prop="dealRate" label="成交率(%)" align="center">
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="成交率=成交量/审单量*100%"
                placement="top"
              >
                <i class="el-icon-warning-outline" />
              </el-tooltip>
              成交率
            </template>
            <template slot-scope="{ row }">
              <span v-if="row.dataList[index].dealRate">{{ row.dataList[index].dealRate }}%</span>
              <span v-else>- -</span>
            </template>
          </el-table-column>
          <el-table-column prop="dealNum" label="成交量" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.dataList[index].dealNum}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="examineNum" label="审单量" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.dataList[index].examineNum}}</span>
            </template>
          </el-table-column>    
          <el-table-column prop="singleGrossProfit" label="单台毛利率" align="center">
            <template slot="header">
              单台毛利
            </template>
            <template slot-scope="{ row }">
              <span>{{ row.dataList[index].singleGrossProfit}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: flex-end;margin-top: 10px;">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="searchFormData.pageNum"
          :page-size="searchFormData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="searchFormData.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import _api from "@/utils/request";
import { MessageBox } from "element-ui";
import GardenSlwct from "@/components/common/gardenSlwct.vue";
import Shadow from "../Shadow.vue";

export default {
  components: {
    GardenSlwct,Shadow
  },
  data() {
    return {
      merchantId: '',
      tableShow: false,
      searchLoading: false,
      tabData: [],
      activeIndex: 0,
      dateTypeOption: [
        { label: "今日", value: "01" },
        { label: "本周", value: "03" },
        { label: "本月", value: "05" },
      ],
      machineTemplateId: "",
      searchFormData: {
        brandId: "",
        machineName: "",
        dateType: "01",
        timeRange: [],
        ascOrDesc: "01",
        orderType: "02",
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      headerList: [],
      tableData: [],
      modelListData: [],
      brandList: [],
      exportLoading: false,
      searchParams: {},
      dataTable: 0,
    };
  },
  created() {
    this.merchantId=this.$route.query.id || ''
    // this.selectBrandList()
    // this.getExamineMachineTemplateList()
  },
  watch: {
    tableShow(val) {
      if(val){
        this.selectBrandList()
        this.getExamineMachineTemplateList()
      }
    }
  },
  methods: {
    // 获取品牌下拉列表
    selectBrandList() {
      //品牌
      _api.getExamineSelectBrandList({merchantId:this.merchantId}).then((res) => {
        if (res.code === 1) {
          this.brandList = res.data
        }
      });
    },
    // 获取模板名称列表
    getExamineMachineTemplateList() {
        _api.getExamineMachineTemplateList({merchantId:this.merchantId}).then(res => {
        if(res.code === 1){
          if(res.data && res.data.length){
            this.tabData = res.data.map(item => {
              return {
                text: item.name,
                value: item.id,
                hideNum: true,
              }
            })
            
            this.machineTemplateId = res.data[0].id
            this.handleCurrentChange()
          }
          
          
        }
      })
    },
    handleCurrentChange(pageNum) {
      if(pageNum){
        this.searchFormData.pageNum = pageNum
      }
      console.log(this.searchFormData.timeRange,'this.searchFormData.timeRange');
      let startTime = ""
      let endTime = ""
      if(this.searchFormData.timeRange && this.searchFormData.timeRange.length){
        startTime = this.searchFormData.timeRange[0]
        endTime = this.searchFormData.timeRange[1]
        this.searchFormData.dateType = ""
      }else if(!this.searchFormData.dateType){
        this.searchFormData.dateType = "01"
      }
      let params = {
        merchantId:this.merchantId,
        machineTemplateId: this.machineTemplateId,
        dateType: this.searchFormData.dateType,
        machineName: this.searchFormData.machineName,
        brandId: this.searchFormData.brandId,
        orderType: this.searchFormData.orderType,
        ascOrDesc: this.searchFormData.ascOrDesc,
        startTime,
        endTime,
        pageNum: this.searchFormData.pageNum,
        pageSize: this.searchFormData.pageSize
      }
      this.searchLoading = true
      this.searchParams = params
      _api.findRankMachineList(params).then(res => {
        if(res.code === 1){
          this.tableData = res.data.dataList.records;
          this.headerList = res.data.headerList
          this.searchFormData.pageNum = res.data.dataList.current
          this.searchFormData.total = res.data.dataList.total
        }
        this.searchLoading = false
      }).catch(()=>{
        this.searchLoading = false
      })
    },
    tabAction(v) {
      this.activeIndex = v;
      this.machineTemplateId = this.tabData[v].value;
      this.dataTable += 1; // 更新 key 强制重新渲染
      this.handleCurrentChange(1)
    },
    changeDateType(item) {
      this.searchFormData.timeRange = null
      this.searchFormData.dateType = item.value;
      this.handleCurrentChange(1)
    },
    sortTableRank(column) {
      if (column.order == "ascending") {
        this.searchFormData.ascOrDesc = "02";
      } else if (column.order == "descending") {
        this.searchFormData.ascOrDesc = "01";
      }
      // this.searchFormData.orderType = "01";
      this.handleCurrentChange(1)
    },
    exportExcel() {
      this.exportLoading = true;
      _api
        .excelRankMachineList(this.searchParams)
        .then((res) => {
          console.log(res);
          const content = res;
          const blob = new Blob([content]);
          let reader = new FileReader(); // 创建读取文件对象
          reader.addEventListener("loadend", () => {
            //
            try {
              let data = JSON.parse(reader.result);
              if (data.code === 0) {
                MessageBox.alert(data.msg, "错误提示", {
                  type: "error",
                });
              }
            } catch (err) {
              const fileName = "详细数据.xlsx";
              if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
              } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
              }
            }
          });
          reader.readAsText(blob, "utf-8");
        }).finally(() => {
          this.exportLoading = false;
        });
    }
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.performance-ranking {
  position: relative;
  padding: 20px;
  .flex {
    display: flex;
    align-items: center;
  }
  .flex-jb {
    display: flex;
    justify-content: space-between;
  }
  .tab-box {
    margin-bottom: 16px;
  }
  .search-box {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 34px;
    flex-wrap: wrap;
    & > div {
      margin-bottom: 20px;
    }
    .date-type {
      width: 60px;
      height: 32px;
      background: #f9fbfd;
      border-radius: 16px;
      border: 1px solid #d9e6ff;
      font-weight: 400;
      font-size: 14px;
      color: #8492a6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      cursor: pointer;
      &.active {
        background: #0981ff;
        color: #ffffff;
      }
    }
    .select-box {
      margin-right: 15px;
      display: flex;
      align-items: center;
      .select-box-lable {
        flex-shrink: 0;
        margin-right: 8px;
      }
      /deep/.el-input--small .el-input__inner {
        border-radius: 50px;
      }
    }
  }
  /deep/.el-table .has-gutter tr th {
    color: #666666;
    font-weight: bold;
    height: 40px;
    padding: 0;
  }
  /deep/.el-table--border th.is-center {
    border-bottom: 1px solid #c4d7fd !important;
  }
}
</style>
